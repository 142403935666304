<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col>
				<Breadcrumb :labels="labels" />
			</b-col>
			<b-col v-if="tabIndex < 1">
				<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
					<b-form-group class="mb-0">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" size="17" />
							</b-input-group-prepend>
							<b-form-input type="text" placeholder="Search" @input="searchValue" />
						</b-input-group>
					</b-form-group>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div>
					<b-tabs card class="caption-add table-custom" v-model="tabIndex">
						<b-tab title="Overview" active class="overview-tab">
							<b-card class="px-table-0 w-100-cl-1 w-20-percent-cl-2 w-20-percent-cl-3">
								<b-row class="align-center">
									<b-col lg="6" style="padding-left: 33px ">
										<h2 class="mb-2"><strong>Organisation Listings</strong></h2>
									</b-col>
									<b-col lg-6>
										<div class="text-right mb-2 pr-2">
											<template v-if="(userData && permission.create)">
												<b-button type="button" variant="primary" class="btn-df size-18 black"
													@click="addOrgnization">
													Add Organisation
												</b-button>
											</template>
											<template v-else>
												<b-button type="button" variant="primary"
													class="btn-df size-18 black btn-disabled-gray" disabled>
													Add Organisation
												</b-button>
											</template>
										</div>
									</b-col>
								</b-row>
								<b-table small :fields="fields" :items="items"
									tbody-tr-class="size-14 table-row cursor-pointer" show-empty @head-clicked="headClicked"
									@row-clicked="detail">
									<template #head(id)="data">
										<span>{{ data.label }}</span>
										<span
											:class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
											style="position: relative; left: 22px;">
											<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
												xmlns="http://www.w3.org/2000/svg">
												<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black"
													stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round" />
												<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round" />
												<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round" />
												<path d="M3 11.9996H6.5" stroke="black" stroke-width="2"
													stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</span>
									</template>
									<template #head()="data">
										<span class="d-block text-center">{{ data.label }}</span>
									</template>
									<template #head(name)="data">
										<span>{{ data.label }}</span>
									</template>
									<template #cell(id)="data">
										<span>{{ data.item.id }}</span>
									</template>
									<template #cell(name)="data">
										<span class="maxTwoRow">{{ data.item.name }}</span>
									</template>
									<template #cell(abbreviation)="data">
										<span class="maxThreeRow text-center">{{ data.item.abbreviation }}</span>
									</template>
									<template #cell(is_client)="data">
										<span class="d-block text-center">
											<template v-if="data.item.is_client == 1">
												<feather-icon icon="CheckIcon" size="20" />
											</template>
											<template v-else>
												<feather-icon icon="XIcon" size="20" />
											</template>
										</span>
									</template>
									<template #cell(is_partner)="data">
										<span class="d-block text-center">
											<template v-if="data.item.is_partner == 1">
												<feather-icon icon="CheckIcon" size="20" />
											</template>
											<template v-else>
												<feather-icon icon="XIcon" size="20" />
											</template>
										</span>
									</template>
									<template #cell(status)="data">
										<span class="d-block text-center">
											<span v-if="data.item.status == 0">Inactive</span>
											<span v-else>Active</span>
										</span>
									</template>
									<template #cell(action)="data">
										<div class="d-block text-center">
											<template v-if="(userData && permission.edit)">
												<span class="px-05 cursor-pointer" @click.stop="edit_product(data.item.id)">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
															fill="#8471FF" />
													</svg>
												</span>
											</template>
											<template v-else>
												<span class="px-05">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
															fill="#b1b1b1" />
													</svg>
												</span>
											</template>
											<template v-if="userData && permission.delete">
												<span class="px-05 cursor-pointer"
													@click.stop="showDeleteAction(data.item.id, data.item.name)">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd"
															d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
															fill="#DD3E47" />
													</svg>
												</span>
											</template>
											<template v-else>
												<span class="px-05">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd"
															d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
															fill="#b1b1b1" />
													</svg>
												</span>
											</template>
										</div>
									</template>
									<template #cell()="data">
										<span class="d-block text-center">{{ data.value }}</span>
									</template>
									<template #empty="scope">
										<template v-if="getsearch">
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data found
											</h4>
										</template>
										<template v-else>
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
										</template>
									</template>
								</b-table>
							</b-card>
							<div v-if="this.total > 0" class="paging-cus relative">
								<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
									aria-controls="custom-table" first-number last-number
									class="just-center"></b-pagination>
								<div class="limit-per">
									<select v-model="selected" class="cursor-pointer">
										<option v-for="limit in limitpage">{{ limit }}</option>
									</select>
								</div>
							</div>
						</b-tab>
						<b-tab title="Alumni Statistic" class="tab-child">
							<b-tabs card class="caption-add table-custom" v-model="tabIndexAlumni">
								<b-tab title="Direct">
									<b-row class="mb-20">
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
														</svg>
													</span>
													Total
												</div>
												<div>{{ charHead.total }}</div>
											</div>
										</b-col>
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
														</svg>
													</span>
													Leaders
												</div>
												<div>{{ charHead.leader }}</div>
											</div>
										</b-col>
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
														</svg>
													</span>
													Specialists
												</div>
												<div>{{ charHead.speacialist }}</div>
											</div>
										</b-col>
									</b-row>
									<b-card no-body class="mb-20">
										<b-card-header class="pt-2 pb-1 pl-2 frm-basic">
											<b-row style="width: 100%; margin: 0;">
												<b-col lg="4" class="px-0">
													<b-card-title>Latest Statistics</b-card-title>
												</b-col>
												<b-col lg="8" class="cadendar-chart"
													style="display: flex; justify-content: flex-end;">
													<b-form-group style="width: 230px; display: inline-block"
														class="mb-0 mr-1">
														<v-select label="title" :options="optionsTypeChart"
															v-model="selectedTypeChart" />
													</b-form-group>
													<div class="border-chart" v-if="selectedTypeChart.value == 1">
														<feather-icon icon="CalendarIcon" size="14" />
														<flat-pickr v-model="rangePicker"
															class="form-control flat-picker bg-transparent border-0 shadow-none"
															placeholder="Select Date" :config="{
																mode: 'range',
																altInput: true,
																altFormat: 'd/m/Y',
																dateFormat: 'Y-m-d',

																onChange: function (selectedDates, dateStr, instance) {

																	if (selectedDates.length == 2) {
																		this.flatMess = false
																		var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
																		range = range / 86400;

																		if (range > 30) {
																			instance.clear()
																			alert();
																		}
																	}
																},
																onOpen: [
																	function (selectedDates, dateStr, instance) {
																		setMess()
																	},
																],

															}" />
													</div>
													<div class="border-chart pl-0" v-else-if="selectedTypeChart.value == 2">
														<datepicker v-model="selectedMonthYear" format="MM/yyyy"
															minimum-view="month" placeholder="Select Month"
															class="w-100-child">
														</datepicker>
													</div>
													<div class="border-chart pl-0" v-else-if="selectedTypeChart.value == 3">
														<datepicker v-model="selectedYear" format="yyyy" minimum-view="year"
															placeholder="Select Year" class="w-100-child">
														</datepicker>
													</div>
												</b-col>
											</b-row>
										</b-card-header>
										<b-card-body class="pb-1" v-if="tabIndex == 1">
											<vue-apex-charts height="600" :options="chartOptions" :series="series"
												show="true" />
										</b-card-body>
									</b-card>
									<b-card class="px-table-0 w-15-percent-cl-1 w-20-percent-cl-2">
										<b-row>
											<b-col lg="6" style="padding-left: 30px ">
												<h2 class="mb-2"><strong>Country Breakdown</strong></h2>
											</b-col>
										</b-row>
										<b-table small :fields="fields_country_Breakdown" :items="country_Breakdown"
											tbody-tr-class="size-14 table-row" show-empty @head-clicked="headClicked_Break">
											<template #head(country_id)="data">
												<span>{{ data.label }}</span>
												<span
													:class="['cursor-pointer iconsort', { sort: data.field.key == sort_Break.field && sort_Break.type == 'desc' }]"
													style="position: relative; left: 45px;">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black"
															stroke-width="2" stroke-linecap="round"
															stroke-linejoin="round" />
														<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 11.9996H6.5" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</span>
											</template>
											<template #head(country_name)="data">
												<span>{{ data.label }}</span>
												<span
													:class="['cursor-pointer iconsort', { sort: data.field.key == sort_Break.field && sort_Break.type == 'desc' }]"
													style="position: relative; left: 60px;">
													<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black"
															stroke-width="2" stroke-linecap="round"
															stroke-linejoin="round" />
														<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
														<path d="M3 11.9996H6.5" stroke="black" stroke-width="2"
															stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</span>
											</template>
											<template #head()="data">
												<div class="d-block text-center">
													<span>{{ data.label }}</span>
													<span
														:class="['cursor-pointer iconsort', { sort: data.field.key == sort_Break.field && sort_Break.type == 'desc' }]"
														style="position: relative; left: 30px;">
														<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black"
																stroke-width="2" stroke-linecap="round"
																stroke-linejoin="round" />
															<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2"
																stroke-linecap="round" stroke-linejoin="round" />
															<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2"
																stroke-linecap="round" stroke-linejoin="round" />
															<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2"
																stroke-linecap="round" stroke-linejoin="round" />
															<path d="M3 11.9996H6.5" stroke="black" stroke-width="2"
																stroke-linecap="round" stroke-linejoin="round" />
														</svg>
													</span>
												</div>
											</template>
											<template #cell(country_name)="data">
												<span>{{ data.item.name }}</span>
											</template>
											<template #cell(country_id)="data">
												<span
													v-if="data.field.key == sort_Break.field && sort_Break.type == 'desc'">
													<template v-if="selected_Break == 10">
														{{ ((total_Break - data.index) - ((currentPage_Break - 1) * 10)) }}
													</template>
													<template v-else-if="selected_Break == 20">
														{{ ((total_Break - data.index) - ((currentPage_Break - 1) * 20)) }}
													</template>
													<template v-else>
														{{ ((total_Break - data.index) - ((currentPage_Break - 1) * 30)) }}
													</template>
												</span>
												<span v-else>
													<template v-if="selected_Break == 10">
														{{ ((currentPage_Break - 1) * 10) + (data.index + 1) }}
													</template>
													<template v-else-if="selected_Break == 20">
														{{ ((currentPage_Break - 1) * 20) + (data.index + 1) }}
													</template>
													<template v-else>
														{{ ((currentPage_Break - 1) * 30) + (data.index + 1) }}
													</template>
												</span>
											</template>
											<template #cell()="data">
												<span class="d-block text-center">{{ data.value }}</span>
											</template>
											<template #empty="scope">
												<h4 class="my-3 text-center"
													style="color: #CFCFCF !important; font-size: 14px !important">No data
												</h4>
											</template>
										</b-table>
									</b-card>
									<div v-if="this.total_Break > 0" class="paging-cus relative">
										<b-pagination v-model="currentPage_Break" :total-rows="total_Break"
											:per-page="perPage_Break" aria-controls="custom-table" first-number last-number
											class="just-center"></b-pagination>
										<div class="limit-per">
											<select v-model="selected_Break" class="cursor-pointer">
												<option v-for="limit in limitpage_Break">{{ limit }}</option>
											</select>
										</div>
									</div>
								</b-tab>
								<b-tab title="Indirect">
									<b-row class="mb-20">
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
														</svg>
													</span>
													Total
												</div>
												<div>{{ charHead.total }}</div>
											</div>
										</b-col>
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z" />
														</svg>
													</span>
													Leaders
												</div>
												<div>{{ charHead.leader }}</div>
											</div>
										</b-col>
										<b-col lg=4>
											<div class="box">
												<div>
													<span class="ico mr-05">
														<svg style="width:30px;height:30px" viewBox="0 0 24 24">
															<path fill="currentColor"
																d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
														</svg>
													</span>
													Specialists
												</div>
												<div>{{ charHead.speacialist }}</div>
											</div>
										</b-col>
									</b-row>
									<Indirect :tabChart="tabIndexAlumni" />
								</b-tab>
							</b-tabs>
						</b-tab>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>

<script>
const moment = require('moment')
import Breadcrumb from '../partial/Breadcrumb'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData.js'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'

import Indirect from './indirect/List'

import globalAdmin from '../model/globalAdmin'

export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		flatPickr,

		VueApexCharts,
		apexchart: VueApexCharts,
		vSelect,
		Datepicker,
		Indirect
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			labels: { "itees-organisations-detail": "" },
			searchQuery: null,
			permission: [],
			fields: [
				{ key: 'id', label: 'ID.' },
				{ key: 'name', label: 'Name' },
				{ key: 'abbreviation', label: 'Abbreviation' },
				{ key: 'is_client', label: 'Is Client' },
				{ key: 'is_partner', label: 'Is Partner' },
				{ key: 'country_name', label: 'Country' },
				{ key: 'status', label: 'Status' },
				{ key: 'action', label: 'Actions' },
			],
			items: [],

			fields_country_Breakdown: [
				{ key: 'country_id', label: 'No.' },
				{ key: 'country_name', label: 'Country Name' },
				{ key: 'number_leader_trained_direct', label: 'Leaders' },
				{ key: 'number_leader_trained_indirect', label: 'Indirect' },
				{ key: 'number_specialist_trained_direct', label: 'Specialist' },
				{ key: 'number_specialist_trained_indirect', label: 'Indirect' },
			],
			country_Breakdown: [],

			tabIndex: 0,
			sort: { "field": "id", "type": "asc" },
			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',

			getsearch: null,
			timer: null,

			tabIndexAlumni: 0,
			charHead: {},

			apexChatData,

			selectedTypeChart: {
				title: 'Month',
				value: 2
			},
			optionsTypeChart: [
				{ title: 'Custom Date Range', value: 1 },
				{ title: 'Month', value: 2 },
				{ title: 'Year', value: 3 },
				{ title: 'Last 5 Years', value: 4 },
			],

			// day
			rangePicker: [],
			day_start: null,
			day_end: null,
			// month
			selectedMonthYear: null,
			charMonthY: null,
			// year
			selectedYear: null,
			charYear: null,

			tabAlumi: 1,

			groupLeaders: [],
			groupSpe: [],

			series: [],
			chartOptions: {
				chart: {
					type: 'bar',
					height: '600',
					stacked: true,
					toolbar: {
						show: false,
					},
					autoSelected: 'pan',
				},
				xaxis: {
					labels: {
						show: true,
						rotate: -35,
						rotateAlways: true,
					},
					categories: [],
				},
				yaxis: {
					stepSize: 50,
					min: 0,
					max: 500,
				},

			},

			maxColumnY: null,
			// Breakdown
			sort_Break: { "field": "country_id", "type": "asc" },
			perPage_Break: 10,
			currentPage_Break: 1,
			total_Break: 0,
			limitpage_Break: ['10', '20', '30'],
			selected_Break: '10',

			rangePicker2: {},

			flatMess: false
		}
	},
	watch: {
		tabIndex: function (val) {
			if (val == 1) {
				this.series.splice(0);  // xóa hết các item đã có
				this.series.push(
					{ name: 'Leaders', data: this.groupLeaders }
				)
				this.series.push(
					{ name: 'Speacialist', data: this.groupSpe }
				)
			}
		},
		getsearch(val) {
			this.fetchList(val)
		},
		currentPage(val) {
			this.fetchList(this.getsearch)
		},
		"$route": function (val) {
			if (val) {
				this.fetchList(this.getsearch)
			}
		},
		tabIndexAlumni: function (val) {
			if (val == 1) {
				this.chartHead(2)
				this.tabAlumi = 2
			}
			else {
				this.chartHead(1)
				this.tabAlumi = 1
			}
		},
		selectedTypeChart(val) {
			if (val.value == 1) {
				if (this.rangePicker.length == 1) {
					this.day_start = this.rangePicker[0]
					this.chartDayRang_method()
				}
				else if (this.rangePicker.length > 1) {
					let path = this.rangePicker.split(" to ")
					this.day_start = path[0]
					this.day_end = path[1]
					this.chartDayRang_method()
				}
			}
			if (val.value == 2) {
				if (this.selectedMonthYear) {
					this.charMonthY = moment(this.selectedMonthYear).format('YYYY-MM')
					this.chartMonth_Method()
				}
			}
			if (val.value == 3) {
				if (this.selectedYear) {
					this.charYear = moment(this.selectedYear).format('YYYY')
					this.chartYear_Method()
				}
			}
			if (val.value == 4) {
				this.chartLast5Years_Method()
			}
		},
		rangePicker(val) {
			if (val.length > 1) {
				let path = val.split(" to ")
				this.day_start = path[0]
				this.day_end = path[1]
			}
			else {
				this.day_start = this.rangePicker[0]
			}
			this.chartDayRang_method()
		},
		selectedMonthYear(val) {
			this.charMonthY = moment(this.selectedMonthYear).format('YYYY-MM')
			this.chartMonth_Method()
		},
		selectedYear(val) {
			this.charYear = moment(this.selectedYear).format('YYYY')
			this.chartYear_Method()
		},
		currentPage_Break(val) {
			this.chartBreakDown_Or()
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.fetchList(this.getsearch)
			}
		},
		selected_Break(val) {
			if (val) {
				this.perPage_Break = val
				this.chartBreakDown_Or()
			}
		}
	},
	mounted() {
		this.selected = '10'
		this.selected_Break = '10'
	},
	created() {
		this.permission = this.checkPermission(this.userData, "Organisation");
		// console.log(this.permission, '9999999');
		this.fetchList(this.getsearch)
		this.chartHead(1)
		this.today()
		this.chartBreakDown_Or()
	},
	methods: {
		alert() {
			if (!this.flatMess) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Please select the day less than 31 days',
						variant: 'danger',
					},
				},
					{
						position: 'top-center'
					})
				this.flatMess = true
			}
		},
		setMess() {
			this.flatMess = false
		},
		today() {
			var d = new Date()
			d.setDate(d.getDate())
			var now = moment(d).format('YYYY-MM')
			var cat = moment(d).format('MM/YYYY')
			this.selectedMonthYear = now
			this.chartOptions.xaxis.categories.push(cat)
		},
		fetchList(valueSearch) {
			this.$store
				.dispatch('auth/Client_Organisation_List', {
					sort: this.sort,
					limit: this.perPage,
					page: this.currentPage,
					filter: { "list_search": [{ "field": "search", "keyword": valueSearch }] }
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
		},
		searchValue(val) {
			this.getsearch = val
		},
		headClicked(key) {
			if (this.sort.field == key) {
				if (this.sort.type == 'asc') this.sort.type = 'desc'
				else this.sort.type = 'asc'
			} else {
				this.sort.field = key
				this.sort.type = 'desc'
			}
			this.fetchList(this.getsearch)
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id, title) {
			this.$store
				.dispatch('auth/deleteOrganisation', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.fetchList(this.getsearch)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				})
		},
		detail(item) {
			this.$router.push(`/admin/organisations/${item.id}`)
		},
		edit_product(id) {
			this.$router.push(`/admin/organisations/edit/${id}`)
		},
		addOrgnization() {
			this.$router.push(`/admin/organisations/add`)
		},
		// chart
		chartHead(type) {
			this.$store
				.dispatch('auth/chartHead_Or', {
					type: type
				})
				.then(response => {
					this.charHead = response.data.data
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		// chart_Or
		chartDayRang_method() {
			this.$store
				.dispatch('auth/chart_Or', {
					type: this.tabAlumi,
					start_date: this.day_start,
					end_date: this.day_end,
					type_date: 'day'
				})
				.then(response => {

					var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if (countNumber <= 2) {
						plusNum = parseInt((firstNum * 0) + 100)
					}
					else if (countNumber == 3) {
						plusNum = parseInt((firstNum * 100) + 100)
					}
					else if (countNumber == 4) {
						plusNum = parseInt((firstNum * 1000) + 1000)
					}
					else if (countNumber == 5) {
						plusNum = parseInt((firstNum * 10000) + 10000)
					}
					else {
						plusNum = number
					}

					this.chartOptions = {
						chart: {
							type: 'bar',
							height: '600',
							stacked: true,
							toolbar: {
								show: false,
							},
							autoSelected: 'pan',
						},
						colors: ['#8471FF', '#F73DA1'],
						plotOptions: {
							bar: {
								columnWidth: '50%',
								colors: {
									backgroundBarColors: [
										'transparent',
										'transparent',
										'transparent',
										'transparent',
										'transparent',
									],
								},

							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: 'bottom',
							fontSize: '14px',
							horizontalAlign: 'center',
							itemMargin: {
								horizontal: 10,
								vertical: 25
							},
						},
						stroke: {
							show: true,
							colors: ['transparent'],
						},
						grid: {
							xaxis: {
								lines: {
									show: true,
								},
							},
						},
						fill: {
							opacity: 1,
						},
						xaxis: {
							labels: {
								show: true,
								rotate: -35,
								rotateAlways: true,
							},
							categories: [],
						},
						yaxis: {
							stepSize: 50,
							min: 0,
							max: plusNum,
						},
					};

					if (this.flatMess) {
						this.chartOptions.xaxis.categories.splice(0)
						this.chartOptions.xaxis.categories.push('')
						this.series.splice(0)
					}
					else {
						this.chartOptions.xaxis.categories.splice(0)

						for (const value of response.data.data.array_month) {
							this.chartOptions.xaxis.categories.push(value)
						}

						this.groupLeaders = response.data.data.array_leader
						this.groupSpe = response.data.data.array_trainer

						this.series.splice(0)  // xóa hết các item đã có
						this.series.push(
							{ name: 'Leaders', data: this.groupLeaders }
						)
						this.series.push(
							{ name: 'Speacialist', data: this.groupSpe }
						)
					}

				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		chartMonth_Method() {
			this.$store
				.dispatch('auth/chart_Or', {
					type: this.tabAlumi,
					month: this.charMonthY,
					type_date: 'month'
				})
				.then(response => {

					var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if (countNumber <= 2) {
						plusNum = parseInt((firstNum * 0) + 100)
					}
					else if (countNumber == 3) {
						plusNum = parseInt((firstNum * 100) + 100)
					}
					else if (countNumber == 4) {
						plusNum = parseInt((firstNum * 1000) + 1000)
					}
					else if (countNumber == 5) {
						plusNum = parseInt((firstNum * 10000) + 10000)
					}
					else {
						plusNum = number
					}

					this.chartOptions = {
						chart: {
							type: 'bar',
							height: '600',
							stacked: true,
							toolbar: {
								show: true,
							},
							autoSelected: 'pan',
						},
						colors: ['#8471FF', '#F73DA1'],
						plotOptions: {
							bar: {
								columnWidth: '50%',
								colors: {
									backgroundBarColors: [
										'transparent',
										'transparent',
										'transparent',
										'transparent',
										'transparent',
									],
								},

							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: 'bottom',
							fontSize: '14px',
							horizontalAlign: 'center',
							itemMargin: {
								horizontal: 10,
								vertical: 25
							},
						},
						stroke: {
							show: true,
							colors: ['transparent'],
						},
						fill: {
							opacity: 1,
						},
						xaxis: {
							labels: {
								show: true,
								rotate: -35,
								rotateAlways: true,
							},
							categories: [],
						},
						yaxis: {
							stepSize: 50,
							min: 0,
							max: plusNum,
						},
					};

					this.chartOptions.xaxis.categories.splice(0)

					for (const value of response.data.data.array_month) {
						this.chartOptions.xaxis.categories.push(value)
					}

					this.groupLeaders = response.data.data.array_leader
					this.groupSpe = response.data.data.array_trainer


					this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
						{ name: 'Leaders', data: this.groupLeaders }
					)
					this.series.push(
						{ name: 'Speacialist', data: this.groupSpe }
					)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		chartYear_Method() {
			this.$store
				.dispatch('auth/chart_Or', {
					type: this.tabAlumi,
					year: this.charYear,
					type_date: 'year'
				})
				.then(response => {

					var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if (countNumber <= 2) {
						plusNum = parseInt((firstNum * 0) + 100)
					}
					else if (countNumber == 3) {
						plusNum = parseInt((firstNum * 100) + 100)
					}
					else if (countNumber == 4) {
						plusNum = parseInt((firstNum * 1000) + 1000)
					}
					else if (countNumber == 5) {
						plusNum = parseInt((firstNum * 10000) + 10000)
					}
					else {
						plusNum = number
					}

					this.chartOptions = {
						chart: {
							type: 'bar',
							height: '600',
							stacked: true,
							toolbar: {
								show: false,
							},
							autoSelected: 'pan',
						},
						colors: ['#8471FF', '#F73DA1'],
						plotOptions: {
							bar: {
								columnWidth: '50%',
								colors: {
									backgroundBarColors: [
										'transparent',
										'transparent',
										'transparent',
										'transparent',
										'transparent',
									],
								},

							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: 'bottom',
							fontSize: '14px',
							horizontalAlign: 'center',
							itemMargin: {
								horizontal: 10,
								vertical: 25
							},
						},
						stroke: {
							show: true,
							colors: ['transparent'],
						},
						grid: {
							xaxis: {
								lines: {
									show: true,
								},
							},
						},
						fill: {
							opacity: 1,
						},
						xaxis: {
							labels: {
								show: true,
								rotate: -35,
								rotateAlways: true,
							},
							categories: [],
						},
						yaxis: {
							stepSize: 50,
							min: 0,
							max: plusNum,
						},
					};

					this.chartOptions.xaxis.categories.splice(0)

					for (const value of response.data.data.array_month) {
						this.chartOptions.xaxis.categories.push(value)
					}

					this.groupLeaders = response.data.data.array_leader
					this.groupSpe = response.data.data.array_trainer


					this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
						{ name: 'Leaders', data: this.groupLeaders }
					)
					this.series.push(
						{ name: 'Speacialist', data: this.groupSpe }
					)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		chartLast5Years_Method() {
			this.$store
				.dispatch('auth/chart_Or', {
					type: this.tabAlumi,
					type_date: 'last5years'
				})
				.then(response => {

					var number = response.data.data.total
					var countNumber = number.toString().length
					var firstNum = String(number)[0]
					var plusNum = 0

					if (countNumber <= 2) {
						plusNum = parseInt((firstNum * 0) + 100)
					}
					else if (countNumber == 3) {
						plusNum = parseInt((firstNum * 100) + 100)
					}
					else if (countNumber == 4) {
						plusNum = parseInt((firstNum * 1000) + 1000)
					}
					else if (countNumber == 5) {
						plusNum = parseInt((firstNum * 10000) + 10000)
					}
					else {
						plusNum = number
					}

					this.chartOptions = {
						chart: {
							type: 'bar',
							height: '600',
							stacked: true,
							toolbar: {
								show: false,
							},
							autoSelected: 'pan',
						},
						colors: ['#8471FF', '#F73DA1'],
						plotOptions: {
							bar: {
								columnWidth: '50%',
								colors: {
									backgroundBarColors: [
										'transparent',
										'transparent',
										'transparent',
										'transparent',
										'transparent',
									],
								},

							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: true,
							position: 'bottom',
							fontSize: '14px',
							horizontalAlign: 'center',
							itemMargin: {
								horizontal: 10,
								vertical: 25
							},
						},
						stroke: {
							show: true,
							colors: ['transparent'],
						},
						grid: {
							xaxis: {
								lines: {
									show: true,
								},
							},
						},
						fill: {
							opacity: 1,
						},
						xaxis: {
							labels: {
								show: true,
								rotate: -35,
								rotateAlways: true,
							},
							categories: [],
						},
						yaxis: {
							stepSize: 50,
							min: 0,
							max: plusNum,
						},
					};

					this.chartOptions.xaxis.categories.splice(0)

					for (const value of response.data.data.array_month) {
						this.chartOptions.xaxis.categories.push(value)
					}

					this.groupLeaders = response.data.data.array_leader
					this.groupSpe = response.data.data.array_trainer


					this.series.splice(0)  // xóa hết các item đã có
					this.series.push(
						{ name: 'Leaders', data: this.groupLeaders }
					)
					this.series.push(
						{ name: 'Speacialist', data: this.groupSpe }
					)
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		chartBreakDown_Or() {
			this.$store
				.dispatch('auth/chartBreakDown_Or', {
					sort: this.sort_Break,
					limit: this.perPage_Break,
					page: this.currentPage_Break,
				})
				.then(response => {
					this.country_Breakdown = response.data.data.data
					this.total_Break = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
				})
		},
		headClicked_Break(key) {
			if (this.sort_Break.field == key) {
				if (this.sort_Break.type == 'asc') this.sort_Break.type = 'desc'
				else this.sort_Break.type = 'asc'
			} else {
				this.sort_Break.field = key
				this.sort_Break.type = 'desc'
			}
			this.chartBreakDown_Or()
		}
	},
};

</script>

<style lang="scss">@import "@/assets/scss/_ite_tab_table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.tab-pt-0 .px-table-0>.card-body {
	padding-top: 0;
}

.Vue-Toastification__container.top-center {
	top: 20px;
}</style>